import environmentUtil from "./environment.util";

const url = environmentUtil.getValue(
  "https://errorbase.innoware.mx",
  "https://errorbase.innoware.mx",
  "http://localhost:3006"
);

const serverUrl = `${url}/api/v1`;

class LogbaseClient {
  #getUrl = (path) => {
    const endpointUrl = `${serverUrl}${path}`;

    return endpointUrl;
  };

  #checkStatus = async (res) => {
    if (res.ok) {
      // res.status >= 200 && res.status < 300
      const json = await res.json();
      return json;
    } else if (res.status === 500) {
      const json = await res.json();
      throw json;
    } else {
      throw new Error(res.statusText);
    }
  };

  #post = async (path, body) => {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    };

    if (body) {
      options.body = JSON.stringify(body);
    }

    const endpointUrl = this.#getUrl(path);

    const res = await fetch(endpointUrl, options);

    const result = await this.#checkStatus(res);

    return result;
  };

  events = {
    insert: (appName, processName, message, stackTrace) => {
      const event = this.#post("/events/insert", {
        appName,
        processName,
        message,
        stackTrace,
      });

      return event;
    },
  };
}

export const getMessage = (ex) => {
  if (ex instanceof Error) {
    return ex.message;
  }

  return "";
};

export const getStackTrace = (ex) => {
  if (ex instanceof Error) {
    return ex.stack;
  }
};

export default new LogbaseClient();
