import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

import "./index.css";
import App from "./components/App";
import { MessageContextProvider } from "./components/contexts/message.context";
import { ExceptionContextProvider } from "./components/contexts/exception.context";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <MessageContextProvider>
      <ExceptionContextProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ExceptionContextProvider>
    </MessageContextProvider>
  </React.StrictMode>
);
