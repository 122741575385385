import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { DateTime } from "luxon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleArrowDown } from "@fortawesome/free-solid-svg-icons";

import logbaseClient from "../../utils/public.errorbase.client";
import TextInput from "../controls/text.input";
import { useException } from "../contexts/exception.context";

const AppsPage = () => {
  const params = useParams();

  const exceptionCtx = useException();

  const [event, setEvent] = useState();
  const [evaluatedEvent, setEvaluatedEvent] = useState();

  useEffect(() => {
    const refresh = async () => {
      try {
        const event = await logbaseClient.events.getById(params.eventId);
        event.data = event.data || "";
        setEvent(event);
      } catch (ex) {
        exceptionCtx.handleException(ex);
      }
    };
    refresh();
  }, []);

  const handleDecompileClicked = async () => {
    try {
      const event = await logbaseClient.events.evaluateStacktrace(
        params.eventId
      );
      setEvaluatedEvent(event);
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  if (!event) return;
  return (
    <>
      <div>
        <label className="form-label">Date</label>

        <TextInput
          value={DateTime.fromISO(event.createdAt).toFormat(
            "yyyy-MM-dd hh:mm:ss"
          )}
          readonly={true}
        />
      </div>
      <div className="mt-2">
        <label className="form-label">App name</label>
        <TextInput value={event.appName} readonly={true} />
      </div>
      <div className="mt-2">
        <label className="form-label">Process name</label>
        <TextInput value={event.processName} readonly={true} />
      </div>
      <div className="mt-2">
        <label className="form-label">
          Stack trace{" "}
          {!evaluatedEvent && (
            <FontAwesomeIcon
              icon={faCircleArrowDown}
              className="clickable btn-primary"
              onClick={handleDecompileClicked}
              color="#0d6efd"
            />
          )}
        </label>
        <div
          className="form-control-plaintext"
          dangerouslySetInnerHTML={{
            __html: event.stackTrace.replaceAll("\n", "<br />"),
          }}
        ></div>

        {evaluatedEvent && (
          <div
            className="form-control-plaintext"
            dangerouslySetInnerHTML={{
              __html: evaluatedEvent.stackTrace.replaceAll("\n", "<br />"),
            }}
          ></div>
        )}
      </div>
      <div className="mt-2">
        <label className="form-label">Data</label>
        <div
          className="form-control-plaintext"
          dangerouslySetInnerHTML={{
            __html: event.data.replaceAll("\n", "<br />"),
          }}
        ></div>
      </div>
    </>
  );
};

export default AppsPage;
