import { useEffect, useState } from "react";
import { DateTime } from "luxon";
import { useParams } from "react-router-dom";

import logbaseClient from "../../utils/public.errorbase.client";

import { useException } from "../contexts/exception.context";
import { useMessage } from "../contexts/message.context";
import TableControl from "../controls/table.control";
import TextInput from "../controls/text.input";

const JobPage = () => {
  const { jobId } = useParams();

  const exceptionCtx = useException();
  const messageCtx = useMessage();

  const [job, setJob] = useState();

  const refresh = async () => {
    try {
      const job = await logbaseClient.jobs.getById(jobId);
      job.results.sort((a, b) => b.createdAt.localeCompare(a.createdAt));
      setJob(job);
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  const handleEnableClicked = async () => {
    try {
      await logbaseClient.jobs.updateEnabled(job._id, true);

      messageCtx.showSuccess("Job enabled");

      await refresh();
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  const handleDisableClicked = async () => {
    try {
      await logbaseClient.jobs.updateEnabled(job._id, false);

      messageCtx.showSuccess("Job disabled");

      await refresh();
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  const handleRunNowClicked = async () => {
    try {
      await logbaseClient.jobs.runNowById(job._id);

      messageCtx.showSuccess("Job will run soon as possible");

      await refresh();
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  useEffect(() => {
    refresh();
    //eslint-disable-next-line
  }, []);

  const getResultIconColor = (result) => {
    if (result.result === "Fejl") return "red";
    else if (result.result === "Success") return "green";
    else return "white";
  };

  const getResultIcon = (result) => {
    return (
      <span
        style={{
          width: 10,
          display: "inline-block",
          height: 10,
          marginRight: 10,
          backgroundColor: getResultIconColor(result),
          borderRadius: 10,
        }}
      ></span>
    );
  };

  const getStatus = (job) => {
    if (!job.enabled) return "Deactive";
    else {
      if (job.processAt) {
        return `Next run - ${DateTime.fromISO(job.processAt).toFormat(
          "yyyy-MM-dd hh:mm:ss"
        )}`;
      } else {
        return "Finished";
      }
    }
  };

  const getJobIconColor = (job) => {
    if (job.results.length === 0) return "gray";

    const lastResult = job.results[0];
    if (lastResult.result === "Fejl") return "red";
    else if (lastResult.result === "Success") return "green";
    else return "transparent";
  };

  const getJobIcon = (job) => {
    return (
      <span
        style={{
          width: 10,
          display: "inline-block",
          height: 10,
          marginRight: 10,
          backgroundColor: getJobIconColor(job),
          borderRadius: 10,
        }}
      ></span>
    );
  };

  if (!job) return null;

  return (
    <div className="container page">
      <h1>Job</h1>
      <div className="form-group mt-3">
        <label className="form-label">Name</label>
        <TextInput
          value={
            <>
              {getJobIcon(job)} {job.name}
            </>
          }
          readonly={true}
        />
      </div>
      <div className="form-group mt-3">
        <label className="form-label">Description</label>
        <TextInput value={job.description} readonly={true} />
      </div>

      <div className="form-group mt-3">
        <label className="form-label">Type</label>
        <TextInput
          value={job.runAt ? `Service - ${job.runAtString}` : "Once"}
          readonly={true}
        />
      </div>

      <div className="form-group mt-3">
        <label className="form-label">Status</label>
        <TextInput value={getStatus(job)} readonly={true} />
      </div>

      {job.results.length > 0 && (
        <div className="form-group mt-3">
          <label className="form-label">Results</label>
          <TableControl
            columns={[
              {
                header: "Date",
                valueExtractor: (r) => (
                  <>
                    {getResultIcon(r)}{" "}
                    {DateTime.fromISO(r.createdAt).toFormat(
                      "yyyy-MM-dd hh:mm:ss"
                    )}
                  </>
                ),
              },
              {
                header: "Result",
                valueExtractor: (r) => r.note,
              },
            ]}
            rows={job.results}
            keyExtractor={(r, index) => index}
          />
        </div>
      )}
      <div className="mt-3 btn-toolbar">
        {job.enabled && job.processAt && (
          <button className="btn btn-primary" onClick={handleRunNowClicked}>
            Run now
          </button>
        )}
        {job.enabled && job.runAt && (
          <button
            className="btn btn-primary ms-1"
            onClick={handleDisableClicked}
          >
            Disable
          </button>
        )}
        {!job.enabled && job.runAt && (
          <button className="btn btn-primary" onClick={handleEnableClicked}>
            Enable
          </button>
        )}
      </div>
    </div>
  );
};

export default JobPage;
