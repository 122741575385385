import environmentUtil from "./environment.util";

const url = environmentUtil.getValue(
  "https://errorbase.innoware.mx",
  "https://errorbase.innoware.mx",
  "http://localhost:3006"
);

const serverUrl = `${url}/api/v1`;

class LogbaseClient {
  #getUrl = (path) => {
    const endpointUrl = `${serverUrl}${path}`;

    return endpointUrl;
  };

  #checkStatus = async (res) => {
    if (res.ok) {
      // res.status >= 200 && res.status < 300
      const json = await res.json();
      return json;
    } else if (res.status === 500) {
      const json = await res.json();
      throw json;
    } else {
      throw new Error(res.statusText);
    }
  };

  #post = async (path, body) => {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    };

    if (body) {
      options.body = JSON.stringify(body);
    }

    const endpointUrl = this.#getUrl(path);

    const res = await fetch(endpointUrl, options);

    const result = await this.#checkStatus(res);

    return result;
  };

  apps = {
    getAll: async () => {
      const apps = await this.#post("/apps/getAll");
      return apps;
    },
    getById: async (appId) => {
      const app = await this.#post("/apps/getById", { appId });
      return app;
    },
    insert: async (name, referers, emailTo) => {
      const app = await this.#post("/apps/insert", { name, referers, emailTo });
      return app;
    },
    delete: async (appId) => {
      await this.#post("/apps/delete", { appId });
    },
    update: async (appId, name, referers, emailTo) => {
      const app = await this.#post("/apps/update", {
        appId,
        name,
        referers,
        emailTo,
      });
      return app;
    },
  };

  events = {
    getByAppAndInterval: async (appName, startDate, endDate) => {
      const events = await this.#post("/events/getByAppAndInterval", {
        appName,
        startDate,
        endDate,
      });
      return events;
    },
    getById: async (eventId) => {
      const event = await this.#post("/events/getById", { eventId });
      return event;
    },
    evaluateStacktrace: async (eventId) => {
      const event = await this.#post("/events/evaluateStacktrace", { eventId });
      return event;
    },
  };

  jobs = {
    getById: async (jobId) => {
      const job = await this.#post("/jobs/getById", {
        jobId,
      });

      return job;
    },
    updateEnabled: async (jobId, enabled) => {
      await this.#post("/jobs/updateEnabled", {
        jobId,
        enabled,
      });
    },
    getLatest: async (amount) => {
      const jobs = await this.#post("/jobs/getLatest", {
        amount,
      });

      return jobs;
    },
    runNowById: async (jobId) => {
      const job = await this.#post("/jobs/runNowById", {
        jobId,
      });

      return job;
    },
  };
}

const logBaseClient = new LogbaseClient();

export default logBaseClient;
