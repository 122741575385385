import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import logbaseClient from "../../utils/public.errorbase.client";

import TableControl from "../controls/table.control";
//import { useMessage } from "../contexts/message.context";
//import { useAppState } from "../contexts/appState.context";
import { useException } from "../contexts/exception.context";

const AppsPage = () => {
  const navigate = useNavigate();

  const exceptionCtx = useException();

  const [apps, setApps] = useState([]);

  useEffect(() => {
    const refresh = async () => {
      try {
        const apps = await logbaseClient.apps.getAll();
        console.log(apps);
        setApps(apps);
      } catch (ex) {
        exceptionCtx.handleException(ex);
      }
    };
    refresh();
  }, []);

  const handleNewClicked = () => {
    navigate("./new");
  };

  const handleRowClicked = (app) => {
    navigate(`./${app._id}`);
  };

  return (
    <>
      <button className="btn btn-primary" onClick={handleNewClicked}>
        New
      </button>
      <TableControl
        columns={[
          { header: "Name", valueExtractor: (a) => a.name },
          {
            header: "Referers",
            valueExtractor: (a) => a.referers.map((r) => <div>{r}</div>),
          },
          { header: "Email to", valueExtractor: (a) => a.emailTo },
        ]}
        keyExtractor={(a) => a._id}
        onRowClicked={handleRowClicked}
        rows={apps}
      />
    </>
  );
};

export default AppsPage;
