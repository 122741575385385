import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

const NavBarControl = () => {
  const navigate = useNavigate();

  const [isExpanded, setIsExpanded] = useState(false);

  const navigateTo = (url) => {
    setIsExpanded(false);
    navigate(url);
  };

  return (
    <section>
      <Navbar
        bg="light"
        expand="lg"
        className="header"
        onToggle={() => setIsExpanded(!isExpanded)}
        expanded={isExpanded}
      >
        <div className="container">
          <Navbar.Brand>
            <Link className="navbar-brand" to="/">
              Admin
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto"></Nav>
            <Nav style={{ alignItems: "center" }}>
              <Nav.Link onClick={() => navigateTo("/apps")}>Apps</Nav.Link>
              <Nav.Link onClick={() => navigateTo("/events")}>Events</Nav.Link>
              <Nav.Link onClick={() => navigateTo("/jobs")}>Jobs</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>
    </section>
  );
};
export default NavBarControl;
