import React, { createContext, useContext } from "react";

import stringUtil from "../../utils/string.util";

import { useMessage } from "./message.context";

const ErrorContext = createContext(null);

export const useException = () => {
  return useContext(ErrorContext);
};

export const messageStates = {
  success: 1,
  error: 2,
  info: 3,
  warning: 4,
};

const isServerError = (ex) => {
  if (!ex?.error) return false;
  return true;
};

const getErrorFromId = () => {
  return "";
};

export const ExceptionContextProvider = ({ children }) => {
  const messageCtx = useMessage();

  const handleException = (ex) => {
    console.log("handle error", ex);
    if (isServerError(ex)) {
      let error = ex.error;

      messageCtx.showError(error);
    } else {
      messageCtx.showError(ex.toString());
    }
  };

  return (
    <ErrorContext.Provider value={{ handleException }}>
      {children}
    </ErrorContext.Provider>
  );
};
