import { useEffect, useState } from "react";
import { DateTime } from "luxon";
import { useNavigate } from "react-router-dom";

import logbaseClient from "../../utils/public.errorbase.client";

import TableControl from "../controls/table.control";
import { useException } from "../contexts/exception.context";

import logBaseClient, {
  getMessage,
  getStackTrace,
} from "../../utils/private.errorbase.client";
import { useMessage } from "../contexts/message.context";

const AppsPage = () => {
  const navigate = useNavigate();

  const exceptionCtx = useException();
  const messageCtx = useMessage();

  const [events, setEvents] = useState([]);
  // const [date, setDate] = useState(DateTime.now().toISODate());
  const [appNames, setAppNames] = useState([]);
  const [appName, setAppName] = useState("");
  const [processName, setProcessName] = useState("");

  useEffect(() => {
    const refresh = async () => {
      try {
        const filter = loadFilter() || {};

        const apps = await logbaseClient.apps.getAll();
        const appNames = Array.from(new Set(apps.map((a) => a.name)));
        appNames.sort((a, b) => a.localeCompare(b));
        appNames.unshift("All");
        setAppNames(appNames);
        if (filter.appName && appNames.includes(filter.appName)) {
          setAppName(filter.appName);
        } else if (appNames.length > 0) {
          setAppName(appNames[0]);
        }

        // if (filter.date) {
        //   setDate(filter.date);
        // }

        if (filter.processName) {
          setProcessName(filter.processName);
        }
      } catch (ex) {
        exceptionCtx.handleException(ex);
      }
    };
    refresh();
  }, []);

  useEffect(() => {
    setEvents([]);
    if (!appName) return;

    const refresh = async () => {
      try {
        const events = await logbaseClient.events.getByAppAndInterval(
          appName,
          DateTime.now().minus({ days: 10 }).toISO(),
          DateTime.now().toISO()
        );
        events.sort((a, b) => b.createdAt.localeCompare(a.createdAt));
        setEvents(events);
      } catch (ex) {
        exceptionCtx.handleException(ex);
      }
    };
    refresh();
  }, [appName]);

  useEffect(() => {
    saveFilter({ appName, date: DateTime.now().toISODate(), processName });
  }, [appName, processName]);

  const loadFilter = () => {
    try {
      const json = window.localStorage.getItem("events.filter");
      const filter = JSON.parse(json);
      return filter;
    } catch (ex) {
      return null;
    }
  };

  const saveFilter = (filter) => {
    window.localStorage.setItem("events.filter", JSON.stringify(filter));
  };

  const insertEvent = async (ex) => {
    try {
      await logBaseClient.events.insert(
        "Innoware",
        "Api>Background worker",
        getMessage(ex),
        getStackTrace(ex)
      );
      messageCtx.showSuccess("Event inserted");
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  const handleInsertClicked = async () => {
    try {
      throw new Error("hello world");
    } catch (ex) {
      await insertEvent(ex);
    }
  };

  let filteredEvents = events;

  if (processName) {
    filteredEvents = filteredEvents.filter((e) =>
      e.processName.toLowerCase().includes(processName.toLowerCase())
    );
  }

  const handleRowClicked = (event) => {
    navigate(`./${event._id}`);
  };

  return (
    <>
      <button onClick={handleInsertClicked} className="btn btn-primary">
        Add test event
      </button>
      {/* <div className="row mt-3">
        <label className="form-label col-sm-2 col-form-label">Date</label>
        <div className="col-sm-10">
          <input
            type="date"
            className="form-control"
            value={date}
            onChange={(e) => setDate(e.target.value)}
          />
        </div>
      </div> */}
      <div className="row mt-2">
        <label className="form-label col-sm-2 col-form-label">App name</label>
        <div className="col-sm-10">
          <select
            className="form-select"
            value={appName}
            onChange={(e) => setAppName(e.target.value)}
          >
            {appNames.map((n) => (
              <option key={n} value={n}>
                {n}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="row mt-2">
        <label className="form-label col-sm-2 col-form-label">
          Process name
        </label>
        <div className="col-sm-10">
          <input
            className="form-control"
            value={processName}
            onChange={(e) => setProcessName(e.target.value)}
          />
        </div>
      </div>
      <div className="mt-4">
        <TableControl
          columns={[
            {
              header: "Timestamp",
              valueExtractor: (e) =>
                DateTime.fromISO(e.createdAt).toFormat("yyyy-MM-dd hh:mm:ss"),
            },
            {
              header: "App name",
              valueExtractor: (e) => e.appName,
            },
            {
              header: "Process name",
              valueExtractor: (e) => e.processName,
            },
            {
              header: "Message",
              valueExtractor: (e) => e.message,
            },
          ]}
          onRowClicked={handleRowClicked}
          keyExtractor={(e) => e._id}
          rows={filteredEvents}
        />
      </div>
    </>
  );
};

export default AppsPage;
