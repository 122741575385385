import { convert } from "html-to-text";

const util = {
  textToHtml: (text) => {
    return text.replaceAll("\n", "<br/>");
  },
  htmlToText: (html) => {
    return convert(html);
  },
  capitalizeFirstLetter: (txt) =>
    txt[0].toUpperCase() + txt.substring(1).toLowerCase(),
  /**
   *
   * @param {String} txt
   * @param {String} query
   * @param {Boolean} caseInsensitive
   */
  includesCI: (txt, query) => {
    return txt.toLowerCase().includes(query.toLowerCase());
  },
};

export default util;
