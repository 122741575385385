import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DateTime } from "luxon";

import logbaseClient from "../../utils/public.errorbase.client";

import { useException } from "../contexts/exception.context";
import TableControl from "../controls/table.control";

const JobsPage = () => {
  const navigate = useNavigate();

  const exceptionCtx = useException();

  const [jobs, setJobs] = useState([]);

  const refresh = async () => {
    try {
      const jobs = await logbaseClient.jobs.getLatest(50);

      jobs.sort((a, b) => a.name.localeCompare(b.name));

      setJobs(jobs);
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  useEffect(() => {
    refresh();
    //eslint-disable-next-line
  }, []);

  const getJobIconColor = (job) => {
    if (job.results.length === 0) return "gray";

    const lastResult = job.results[0];
    if (lastResult.result === "Fejl") return "red";
    else if (lastResult.result === "Success") return "green";
    else return "transparent";
  };

  const getJobIcon = (job) => {
    return (
      <span
        style={{
          width: 10,
          display: "inline-block",
          height: 10,
          marginRight: 10,
          backgroundColor: getJobIconColor(job),
          borderRadius: 10,
        }}
      ></span>
    );
  };

  const getNextRunDate = (job) => {
    if (!job.enabled) return "Deactive";
    if (job.processAt) {
      return DateTime.fromISO(job.processAt).toFormat("yyyy-MM-dd hh:mm:ss");
    }

    return "";
  };

  const getOverdueIcon = (job) => {
    if (!job.enabled) return "";
    if (job.processAt) {
      if (
        DateTime.fromISO(job.processAt) < DateTime.now().minus({ hours: 2 })
      ) {
        return (
          <span
            style={{
              width: 10,
              display: "inline-block",
              height: 10,
              marginRight: 10,
              backgroundColor: "red",
              borderRadius: 10,
            }}
          ></span>
        );
      }
    }

    return "";
  };

  const handleJobClicked = (job) => {
    navigate(`./${job._id}`);
  };

  return (
    <div className="container page">
      <h1>Jobs</h1>

      <TableControl
        columns={[
          {
            header: "Name",
            valueExtractor: (j) => (
              <>
                {getJobIcon(j)} {j.name}
              </>
            ),
          },
          {
            header: "Runs",
            valueExtractor: (j) => (
              <>
                {getOverdueIcon(j)}
                {getNextRunDate(j)}
              </>
            ),
          },
        ]}
        rows={jobs}
        onRowClicked={handleJobClicked}
        keyExtractor={(j) => j._id}
      />
    </div>
  );
};

export default JobsPage;
