import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import logBaseClient from "../../utils/public.errorbase.client";
import validateUtil from "../../utils/validate.util";

import TextInput from "../controls/text.input";
import TextareaInput from "../controls/textarea.input";
//import { useMessage } from "../contexts/message.context";
//import { useAppState } from "../contexts/appState.context";
import { useException } from "../contexts/exception.context";

const AppPage = () => {
  const navigate = useNavigate();
  const params = useParams();

  const exceptionCtx = useException();

  const [oldApp, setOldApp] = useState();
  const [newApp, setNewApp] = useState();

  useEffect(() => {
    const refresh = async () => {
      try {
        if (params.appId) {
          const app = await logBaseClient.apps.getById(params.appId);
          setOldApp(app);
          setNewApp({ ...app });
        } else {
          setNewApp({ name: "", referers: [], emailTo: "" });
        }
      } catch (ex) {
        exceptionCtx.handleException(ex);
      }
    };
    refresh();
  }, []);

  const isFormOk = () => {
    if (!newApp.name) return false;
    if (
      newApp.referers.length === 0 ||
      newApp.referers.some((r) => !validateUtil.url.isValid(r))
    )
      return false;
    if (!validateUtil.email.isValid(newApp.emailTo)) return false;

    return true;
  };

  const handleInsertClicked = async () => {
    try {
      await logBaseClient.apps.insert(
        newApp.name,
        newApp.referers,
        newApp.emailTo
      );
      navigate("./..");
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  const handleDeleteClicked = async () => {
    try {
      await logBaseClient.apps.delete(newApp._id);
      navigate("./..");
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  const handleUpdateClicked = async () => {
    try {
      await logBaseClient.apps.update(
        newApp._id,
        newApp.name,
        newApp.referers,
        newApp.emailTo
      );
      navigate("./..");
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  if (!newApp) return;

  return (
    <>
      <h1>{oldApp ? "Update app" : "Insert app"}</h1>
      <div>
        <label className="form-label">Name</label>
        <TextInput
          value={newApp.name}
          onChange={(value) => setNewApp({ ...newApp, name: value })}
        />
      </div>
      <div className="mt-2">
        <label className="form-label">Referers (one per line)</label>
        <TextareaInput
          value={newApp.referers.join("\n")}
          onChange={(value) =>
            setNewApp({ ...newApp, referers: value.split("\n") })
          }
        />
      </div>
      <div className="mt-2">
        <label className="form-label">Email to</label>
        <TextInput
          value={newApp.emailTo}
          onChange={(value) => setNewApp({ ...newApp, emailTo: value })}
        />
      </div>
      <div className="mt-2">
        {!oldApp && (
          <button
            className="btn btn-primary"
            onClick={handleInsertClicked}
            disabled={!isFormOk()}
          >
            Save
          </button>
        )}
        {oldApp && (
          <>
            <button
              className="btn btn-primary"
              onClick={handleUpdateClicked}
              disabled={!isFormOk()}
            >
              Save
            </button>
            <button
              className="btn btn-danger ms-1"
              onClick={handleDeleteClicked}
            >
              Delete
            </button>
          </>
        )}
      </div>
    </>
  );
};

export default AppPage;
