import { Route, Routes } from "react-router-dom";

import AppsPage from "./apps/apps.page";
import AppPage from "./apps/app.page";
import EventsPage from "./events/events.page";
import EventPage from "./events/event.page";
import JobsPage from "./jobs/jobs.page";
import JobPage from "./jobs/job.page";

import "./App.css";
import NavbarControl from "./navbar.control";

function App() {
  return (
    <div>
      <NavbarControl />
      <div className="container mt-3">
        <Routes>
          <Route path="/">
            <Route path="apps">
              <Route index element={<AppsPage />} />
              <Route path="new" element={<AppPage />} />
              <Route path=":appId" element={<AppPage />} />
            </Route>
            <Route path="events">
              <Route index element={<EventsPage />} />
              <Route path=":eventId" element={<EventPage />} />
            </Route>
            <Route path="jobs">
              <Route index element={<JobsPage />} />
              <Route path=":jobId" element={<JobPage />} />
            </Route>
          </Route>
        </Routes>
      </div>
    </div>
  );
}

export default App;
